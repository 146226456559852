<template>
    <div class="">
        <button class="button" id="btn-print" @click="print">Cetak</button>
   
            <div class="w-100" style="border: 1px solid #333;" id="adit_celeng">
                <table border="0" class="custom-table">
                    <tbody>
                        <tr>
                            <td style="width: 30mm;">No. RM</td>
                            <td style="width: 2.5mm;text-align: center;">:</td>
                            <td>-</td>
                        </tr>

                        <tr>
                            <td>Nama</td>
                            <td style="text-align: center;">:</td>
                            <td>-</td>
                        </tr>

                        <tr>
                            <td>Tanggal Lahir</td>
                            <td style="text-align: center;">:</td>
                            <td>-</td>
                        </tr>

                        <tr>
                            <td>Jenis Kelamin</td>
                            <td style="text-align: center;">:</td>
                            <td>-</td>
                        </tr>

                        <tr>
                            <td>Alamat</td>
                            <td style="text-align: center;">:</td>
                            <td>-</td>
                        </tr>
                    </tbody>
                </table>
            </div>
      
    </div>
</template>

<script>
import moment from "moment";
moment.locale("ID");
export default {
    name: "cetak_label_pasien",
    components: {},
    data() {
        return {
            
        };
    },
    methods:{
        async print () {
      // Pass the element id here
      await this.$htmlToPaper('adit_celeng');
    }
    }
  
};
</script>

